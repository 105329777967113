<template>
  <div class="error-notification rounded-sm bg-bg-danger-light px-5 lg:px-10 flex items-center">
    <span class="mr-5 lg:mr-10">
      <icon
        class="icon"
        icon="exclamationCircle"
        color="text-color-danger"
        height="20"
        width="20"
      />
    </span>
    <p class="error-notification__msg">{{ text }}</p>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
export default {
  components: {
    icon,
  },
  props: { text: { type: String, default: '' } },
}
</script>

<style lang="scss">
@import '@src/design/_mediaquery-mixin.scss';
.error-notification {
  height: 62px;
  &__msg {
    color: var(--text-color-danger);
  }
}
</style>
