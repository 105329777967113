import { objectDeepCopy } from '@src/utils/generalUtil.js'

export default {
  data() {
    return {
      themes: objectDeepCopy(this.$store.state.settings.siteSettingsDefaults.themes),
      baseLangs: objectDeepCopy(this.$store.state.base.languages),
      langs: objectDeepCopy(this.$store.state.base.languages),
      shortLang: this.$store.state.base.languages.map((lang) => lang.short),
      longLang: this.$store.state.base.languages.map((lang) => lang.long),
      allowdThemes: this.$store.state.settings.siteSetting?.permissible_themes?.permissible_themes,
      allowdLangs:
        this.$store.state.settings.siteSetting?.permissible_languages?.permissible_languages,
    }
  },
  mounted() {
    this.themes = this.themes.filter((theme) => this.allowdThemes?.some((val) => val === theme))
    this.langs = this.langs.filter((lang) => this.allowdLangs?.some((val) => val === lang.short))
    this.shortLang = this.shortLang.filter((sl) => this.langs?.some((val) => sl === val.short))
    this.longLang = this.longLang.filter((ll) => this.langs?.some((val) => ll === val.long))
  },
  computed: {
    isUrdu() {
      const lang = JSON.parse(localStorage.curLanguage).short
      let returnVal = false
      if (lang == 'ur') {
        returnVal = true
      }
      return returnVal
    },
  },
  methods: {
    selectTheme(newTheme) {
      this.$store.commit(
        'base/SET_STATE_BASE_THEME',
        JSON.stringify({ name: newTheme.replace(' ', '-') }),
      )
      this.$store.commit('base/SET_BASE_THEME', document.documentElement)
    },
    saveSelectedTheme(selectedTheme) {
      this.$store.commit(
        'base/SET_THEME_TO_LOCAL_STORAGE',
        JSON.stringify({ name: selectedTheme.replace(' ', '-') }),
      )
    },
    selectLang(lang, type = 'long') {
      let selectedLanguage = {}
      this.langs.forEach((language) => {
        if (language[type] === lang) {
          selectedLanguage = language
          this.$i18n.locale = language.short
          this.$store.dispatch('base/loadInitialLang', language)
        }
      })
      return selectedLanguage
    },
    saveLanguageToLocalStorage(selectedLanguage) {
      this.$store.commit('base/SET_LANG_LS', selectedLanguage)
    },
  },
}
