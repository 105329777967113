<template>
  <Auth title="Lets Get Started" :description="pageDescription" header-logo="logoheader">
    <main>
      <ValidationObserver v-slot="{ handleSubmit }">
        <UIErrorNotification v-if="errorMsg" class="mt-5" :text="errorMsg" />
        <div
          class="mb-3 font-inter lg:text-lg sm:text-base text-sm text-primary-purple-700 flex justify-end font-medium"
        >
          <span class="cursor-pointer" @click="switchLoginWith">
            {{ loginButtonText }}
          </span>
        </div>
        <UiInput
          v-if="loginWithEmail"
          id="email"
          v-model.trim="loginData.email"
          type="email"
          name="email"
          rules="required|email"
          :value="loginData.email"
          placeholder="Email"
          title="email"
          @keyup.enter="handleSubmit(loginHandler)"
        />
        <PhoneNumber
          v-else
          v-model="phoneNumber"
          :rules="!loginData.email ? 'required|validNumber' : 'validNumber'"
          title="Phone Number"
          @update="countryChanged"
        />
        <div v-if="isDomainSelectionActive" class="relative">
          <UiInput
            id="text"
            v-model.trim="loginData.sub_domain"
            type="text"
            name="domain"
            :value="loginData.sub_domain"
            placeholder="Institute Sub-Domain"
            title="SUB_DOMAIN"
            rules="required"
            @keyup.enter="handleSubmit(loginHandler)"
          />
          <!-- rules alpha_num -->
          <div
            class="subdomain flex justify-end items-center bg-transparent font-inter text-primary-gray-500 font-medium px-1 lg:px-1"
          >
            <div class="lg:text-xs xs:text-xxs md:text-s xl:text-base domain-text">
              {{ hostName }}
            </div>
          </div>
        </div>
        <UiInput
          id="password"
          v-model.trim="loginData.password"
          :class="errorMsg ? '' : ''"
          class="relative"
          type="password"
          name="password"
          rules="required"
          placeholder="Password"
          :value="loginData.password"
          title="Password"
          @keyup.enter="handleSubmit(loginHandler)"
        />
        <div class="flex justify-between items-center mb-6">
          <div>
            <UiCheckbox
              v-model="loginData.remember_me"
              label="Remember Me"
              size="sm"
              lable-direction="flex-row-reverse gap-2"
            />
          </div>

          <div class="forgot-Password flex justify-end">
            <router-link
              to="/user/forget-password"
              class="font-inter font-semibold text-sm text-primary-purple-700"
            >
              <a>
                <span v-i18n="loginPage">Forgot Password ?</span>
              </a>
            </router-link>
          </div>
        </div>
        <UIButton
          button="primary"
          :disabled="isLoading"
          class="w-full h-8 sm:h-12"
          @click="handleSubmit(loginHandler)"
        >
          <span v-if="isLoading">
            <Loader />
          </span>

          <span v-else v-i18n="loginPage">Log In</span>
        </UIButton>
        <p v-if="isDomainSelectionActive" class="mt-8 font-inter text-sm flex justify-center gap-1">
          <span v-i18n="loginPage" class="font-normal text-primary-gray-500">SIGNUP_INFO</span>
          <span
            v-i18n="loginPage"
            class="font-semibold text-primary-purple-700 cursor-pointer"
            @click="redirectToSignup"
          >
            GET_STARTED
          </span>
        </p>
      </ValidationObserver>
    </main>
  </Auth>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
import Auth from '@layouts/auth.vue'
import UIErrorNotification from '@components/UiElements/UIErrorNotification.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { removeEmptyKeysFromObject, getDefaultRoute } from '@src/utils/generalUtil.js'
import { siteDomainHandler } from '@src/utils/site-utils.js'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import themeAndLangMixin from '@src/mixins/theme-and-lang-mixins'
import { DOMAIN_TITLE } from '@src/constants/general-constants'
import { Form as ValidationObserver } from 'vee-validate'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { redirectToSignup } from '@src/utils/generalUtil.js'

export default {
  name: 'SignInPage',

  components: {
    Loader,
    UiInput,
    UiCheckbox,
    Auth,
    ValidationObserver,
    UIErrorNotification,
    PhoneNumber,
    UIButton,
  },
  mixins: [themeAndLangMixin],
  data() {
    return {
      TENANT_ROLES,
      isLoading: false,
      showText: false,
      errorMsg: null,
      loginData: {
        password: '',
        sub_domain: '',
        remember_me: false,
        fcm_token: '',
      },
      error: '',
      isDomainSelectionActive: true,
      loginPage: 'loginPage',
      phoneNumber: '',
      phoneNumberValid: false,
      loginWithEmail: true,
      instituteName: '',
    }
  },
  page: {
    title: 'Signin',
    meta: [{ name: 'description', content: 'Sign in MyQampus' }],
  },
  computed: {
    loginButtonText() {
      return this.loginWithEmail ? 'Sign In with Phone' : 'Sign In with Email/Username'
    },
    ...mapGetters('auth', ['userRoles']),
    ...mapState({
      systemSettings: (state) => state.settings.siteSetting,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
    hostName() {
      let host = window.location.hostname
      if (host.includes('app')) return '.' + host.slice(4)
      else return '.' + host
    },
    pageDescription() {
      return `Welcome to ${this.instituteName}. Where you can automate your routine institute related work.`
    },
  },
  /*
   * In created hook
   * Check if the user allows notification or not if not show toast message
   * Check if user surf site from domain then hide domain field and take domain from host eg.demo.dev.myqampus.com
   */
  created() {
    this.notificationPermissionHandler()
    this.domainHandler()
    let token = this.$route.query.token
    let user = this.$route.query.user
    if (token) {
      this.loginConfigueredTenant(token, user)
      this.$router.go()
    }
  },
  methods: {
    redirectToSignup,
    loginConfigueredTenant(token = '', user = null) {
      localStorage.setItem('jwt', token)
      localStorage.setItem('auth.currentUser', user)
      localStorage.setItem('selectedRoleId', TENANT_ROLES.SUPER_ADMIN)
    },
    countryChanged(payload) {
      this.loginData.phone = payload.formattedNumber
      this.phoneNumberValid = payload.isValid
    },
    switchLoginWith() {
      this.loginWithEmail = !this.loginWithEmail
      this.loginData.email = ''
      this.loginData.phone = ''
    },
    route() {
      var activeRole = this.$store.state.layout.activeRole
      let route = getDefaultRoute(activeRole, this.currentCampusScope, this.currentSectionScope)
      this.$router?.push({ name: route })
      if (this.userRoles.length > 1)
        this.$store.commit('layout/SET_SHOW_ROLE_SWITCHING_SUITE_MODAL', true)
    },
    /*
     * Use this method to make user login in tenant
     * Getting email,domain and password from user and check its valid or not
     * Getting invalid keyword as param to to check form data validation
     * If user is authentic then make user logged in and push him/her to his/her relevant route
     */
    loginHandler() {
      this.isLoading = true
      this.loginData.fcm_token = localStorage.getItem('fcmToken') || null

      removeEmptyKeysFromObject(this.loginData)

      if (this.loginData.email && this.loginData.phone) {
        this.$store.commit(
          'toast/NEW',
          { type: 'error', message: 'Either login with email or phone' },
          { root: true },
        )
        this.isLoading = false
      } else {
        this.logIn(this.loginData)
          .then(() => {
            this.isLoading = false
            var signUpModal = localStorage.getItem('signUpSlider')
            const authToken = localStorage.getItem('jwt') || null

            if (signUpModal === 'true') this.$router?.push({ name: 'setup-slider' })
            else this.route()

            if (!this.$socket?.isConnected()) {
              this.$connectSocket('/tenant-notifire-socket', authToken)
            }
            this.setDefaultSettings() // Set System Settings
          })
          .catch((error) => {
            this.isLoading = false
            if (error.code === 401) {
              this.errorMsg = error.message
            }
          })
      }
    },
    setDefaultSettings() {
      // Language
      let language = this.selectLang(
        this.systemSettings?.default_language?.default_language,
        'short',
      )
      this.saveLanguageToLocalStorage(language)

      // Theme
      let newTheme = this.systemSettings?.default_theme?.default_theme || 'green'
      if (newTheme == 'light') {
        newTheme = 'green'
      }
      this.saveSelectedTheme(newTheme)
      this.selectTheme(newTheme)
    },

    notificationPermissionHandler() {
      if (Notification.permission === 'default') {
        Notification.requestPermission()
      } else if (Notification.permission === 'denied') {
        this.$store.commit(
          'toast/NEW',
          {
            message:
              'You have disabled the notification,if you want to get notification please enable it from info icon',
            type: 'info',
          },
          { root: true },
        )
      }
    },
    async domainHandler() {
      this.isDomainSelectionActive = siteDomainHandler().isDomainFieldVisible
      this.loginData.sub_domain = siteDomainHandler().domain
      if (this.loginData.sub_domain) {
        let payload = this.loginData.sub_domain.includes('.com')
          ? { site_url: this.loginData.sub_domain }
          : { sub_domain: this.loginData.sub_domain }
        this.$store.commit('layout/IS_LOADING', true)
        await this.getPublicSettings(payload)
        this.$store.commit('layout/IS_LOADING', false)
      }
      this.setInstituteName()
    },
    setInstituteName() {
      this.instituteName = localStorage.getItem('generalSettingSiteTitle') || DOMAIN_TITLE
    },
    ...mapActions('auth', ['logIn']),
    ...mapActions('settings', ['getPublicSettings']),
  },
}
</script>

<style lang="scss" scoped>
.subdomain {
  position: absolute;
  top: 36px;
  right: 10px;
  color: var(--text-placeholder);
}
.signin-button {
  background: transparent linear-gradient(265deg, #0f9f5d 0%, #09814a 100%) 0% 0% no-repeat
    padding-box;
}
body[dir='rtl'] {
  .subdomain {
    right: auto;
    left: 10px;
  }
}
</style>

<style lang="scss" module>
.icon {
  right: 17px;
  bottom: 14px;
}
</style>

<style lang="scss">
@import './auth.scss';
</style>
